import React from 'react';
import { Container } from 'components/common';
import contact from 'assets/illustrations/contact.svg';
import { Wrapper, Details, Thumbnail } from './styles';
// import ContactForm from './ContactForm'

export const Contact = () => (
  <Wrapper as={Container} id="contact">
    <Details>
      {/* <ContactForm /> */}
      <p>
        Contact me at <a href="mailto:vaib.kapoor15@gmail.com">vaib.kapoor15@gmail.com</a> or on{' '}
        <a href="https://www.linkedin.com/in/vk2/">LinkedIn</a>, I'm happy to chat at anytime.
      </p>
    </Details>
    <Thumbnail>
      <img src={contact} alt="I’m Vab and I'm a Software Engineer!" />
    </Thumbnail>
  </Wrapper>
);
