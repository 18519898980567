import React from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/skills.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dev} alt="I’m Vab and I'm a Software Engineer!" />
      </Thumbnail>
      <Details>
        {/* <h1>Hi There!</h1> */}
        <p>
          I'm a Software Engineer in my 4th year of Computer Science at the University of Waterloo. I'm currently looking for full time opportunities for Fall 2021!
        </p>
        {/* <Button as={AnchorLink} href="#contact">
          Hire me
        </Button> */}
        {
          // TODO: Update resume to latest version
        }
        {
          // TODO: Fix margin padding on buttons so it's dynamic, and not a fixed 5px, renders incorrectly on mobile
        }
        <Button as="a" href="/resume.pdf" style={{ margin: '0 5px 0 5px' }}>
          Resume
        </Button>
        <Button as="a" href="https://www.github.com/vaibzzz123" style={{ margin: '0 5px 0 5px' }}>
          Github
        </Button>
        <Button as="a" href="https://www.linkedin.com/in/vk2/" style={{ margin: '0 5px 0 5px' }}>
          LinkedIn
        </Button>
      </Details>
    </SkillsWrapper>
  </Wrapper>
);
